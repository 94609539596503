import {legacy_createStore} from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from './root-reducer';


const persistConfig = {
    key: 'root',
    storage,
    // blacklist: ['modal']
    // whiteList: ['navigation']
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = legacy_createStore(persistedReducer);
export const persist_store = persistStore(store);
