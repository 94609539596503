import party from "party-js";
import {Share} from "@capacitor/share";
import html2canvas from "html2canvas";

export function ShowConfettiOnSolve() {
    party.confetti(new party.Rect(window.document.body.offsetWidth/2, window.document.body.offsetHeight/2 - 60), {
        count: party.variation.range(2, 15),
    });
}

export function OnClickShareButton(twister: string, time: number, percent: number) {
    if(!Share.canShare()) {
        console.warn('Sharing is not supported');
        return;
    }

    const canvasDocument = document.querySelector("#root > div") as HTMLElement;
    html2canvas(canvasDocument, {
        onclone: function(documentClone) {
            let screenshotElement = documentClone.querySelector("#root > div");
            if(screenshotElement != null) {
                screenshotElement.classList.add("screenshot");
            }
        }
    }).then(canvas => {
        canvas.toBlob(async (blob) => {
            if(blob == null) return;
            // const files = [new File([blob], 'pixie-mart.png', { type: blob.type })];
            const files = [URL.createObjectURL(blob)];
            const shareData = {
                title: 'Pixie Mart - Tongue Twister',
                text: 'Can you beat my score, ' +
                    '\nI\'m able to match '+ percent +'% within '+ time +' sec to pronounce ' +
                    '\n"' + twister + '" at',
                url: 'https://www.tonguetwist.in/',
                files,
            }

            await Share.share(shareData).catch((err:any) => {
                if (err.name !== 'AbortError') {
                    console.error(err.name, err.message);
                }
            });

        });
    });
}
