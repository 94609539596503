import React from "react";
import {DiffInterface} from "../TwisterInterfaces";
import {TwisterInterfaces} from "../../../interfaces/TwisterInterfaces";
import {isEmptyString} from "../../../utils/validators/Validator";
import * as Diff from "diff";
import {ShowConfettiOnSolve} from "../TwisterPageFunctions";



export function resetTwisterResult(setStartTimer: React.Dispatch<React.SetStateAction<number>>,
                                   setDiffTwister: React.Dispatch<React.SetStateAction<DiffInterface[]>>,
                                   setMatchedTime: React.Dispatch<React.SetStateAction<number>>,
                                   setMatchedPercent: React.Dispatch<React.SetStateAction<number>>) {
    setStartTimer(Date.now() / 1000);
    setMatchedTime(Infinity);
    setMatchedPercent(0);
    setDiffTwister([]);
}

export function onClickNextButton(twisterIndex: number,
                                  setSelectedTwisterIndex: (index: number) => void,
                                  counter: number, twisterLength: number) {
    let nextCounter = twisterIndex + counter;
    if(nextCounter >= twisterLength) nextCounter = 0;
    else if(nextCounter < 0) nextCounter = twisterLength - 1;

    setSelectedTwisterIndex(nextCounter);
}

export function onRecordingComplete(transcript: string, startTimer: number, selectedTwister: string,
                                    twister: TwisterInterfaces,
                                    setPlayerScoreDispatch: (score: number) => void,
                                    setDiffTwister: React.Dispatch<React.SetStateAction<DiffInterface[]>>,
                                    setMatchedTime: React.Dispatch<React.SetStateAction<number>>,
                                    setMatchedPercent: React.Dispatch<React.SetStateAction<number>>) {
    if (isEmptyString(transcript)) return;

    let matched_time  = Math.round(Date.now()/1000 - startTimer);
    if(matched_time > 1000) {
        matched_time = 1000;
    } else if (matched_time < 1) {
        matched_time = 1;
    }

    const REGX_REPLACER = /[.,'":;]/g;
    selectedTwister = selectedTwister.replaceAll(REGX_REPLACER, '').trim();
    transcript = transcript.replaceAll(REGX_REPLACER, '').trim();

    const total_words = (selectedTwister.trim().match(/ /g)||[]).length + 1;
    let matched_words = 0;
    let difference = Diff.diffWords(selectedTwister, transcript, {ignoreCase: true}) as DiffInterface[];
    difference.forEach((part: DiffInterface) => {
        if(part.added) {
            part.color = 'text-success';
        } else if(part.removed) {
            part.color = 'text-danger text-strike';
        } else {
            part.color = '';
            if(part.value.trim().length > 0) {
                matched_words += (part.value.trim().match(/ /g)||[]).length + 1;
            }
        }
    });

    const matched_percent = Math.round(matched_words * 100 / total_words);

    setMatchedTime(matched_time);
    setMatchedPercent(matched_percent);
    setDiffTwister(difference);

    if(matched_percent > 0) {
        const twister_level = Number.parseInt(twister.selectedLevel);
        let score = twister.playerScore;
        score += Math.max(0, (18 + twister_level * 7) - matched_time) +
            Math.round((twister_level + 1) * (matched_percent * 3) / 100);
        setPlayerScoreDispatch(score);
        if(matched_percent === 100) {
            ShowConfettiOnSolve();
        }
    }
}
