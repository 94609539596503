import React from 'react';
import {isBrowser} from "../../utils/PlatformUtils";
import {APP_STORE_URL, PLAY_STORE_URL} from "../../constants/Constant";
import app_store from "../../statics/images/app_store.png";


export function Footer() {
    return (
        <section className={"bg-dark"} style={{background: "#343a40"}}>
            {
                isBrowser() &&
                <div className={"d-flex justify-content-center"}>
                    <div style={{height: 82}}>
                        <a href={PLAY_STORE_URL}>
                            <img alt='Try it on Google Play'
                                 src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'
                                 className={"img-object-fit-contain"}/>
                        </a>
                    </div>

                    <div style={{height: 82}} className={"p-1"}>
                        <a href={APP_STORE_URL}>
                            <img alt='Try it on App Store'
                                 src={app_store}
                                 className={"img-object-fit-contain"}/>
                        </a>
                    </div>
                </div>
            }

            <div className={"text-center text-secondary p-2"}>
                Pixie Mart | <span
                className={"small text-secondary m-0"}> Tongue Twister powered by Chat GPT-4</span>
            </div>
        </section>
    );
}

export default Footer;
