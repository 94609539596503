import React, {Component} from "react";
import './App.css';
import './animation.css';
import TwisterHome from "../twister/home/TwisterHome";
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
import {HOME_URL} from "../../constants/application/ApplicationUrl";
import {Connector} from "../../../../redux/Connector";
import CenterScreenModal from "../modals/centermodal/CenterScreenModal";
import {GOOGLE_ANALYTICS_UA} from "../../constants/Config";
import ReactGA from "react-ga4";
import {isPlatform} from "@ionic/react";
import {VersionCheck} from "../version/VersionCheck";

ReactGA.initialize(GOOGLE_ANALYTICS_UA);

class App extends Component {

    componentDidMount() {
        VersionCheck(this.props);
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: window.document.title });
    }

    render() {
        return (
            <div className="App d-flex flex-column">
                {
                    (isPlatform("ios")) &&
                    <div className={"safe-top-area"} />
                }
                <Router>
                    <div className={"flex-fill d-flex flex-column sc-no-flex-fill"}>
                        <Switch>
                            <Route path={HOME_URL} exact={true} render={() => <TwisterHome />} />
                            <Redirect to={HOME_URL} from={"/*"} />
                        </Switch>
                    </div>
                </Router>
                <CenterScreenModal />
            </div>
        );
    }
}

export default Connector(App);
