import ReactGA from "react-ga4";

const GoogleAnalyticsEventTracker = ( category = "Event Category") => {
    return (action ="action", label="label") => {
        ReactGA.event({
            category: category,
            action: action,
            label: label})
    };
}

export const GaEventTracker = GoogleAnalyticsEventTracker();
