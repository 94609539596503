import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Share} from "@capacitor/share";
import React, {useCallback, useEffect, useState} from "react";
import {OnClickShareButton} from "./TwisterPageFunctions";
import {RecognitionComponentInterface} from "../../interfaces/RecognitionComponentInterface";
import {onClickNextButton, onRecordingComplete, resetTwisterResult} from "./home/TwisterFunctions";
import {setPlayerScore, setTwisterIndex} from "../../../../redux/twister/twister.action";
import {useDispatch, useSelector} from "react-redux";
import {TongueTwisterHookComponent} from "./TongueTwisterHookComponent";
import {TwisterInterfaces} from "../../interfaces/TwisterInterfaces";
import rootReducer from "../../../../redux/root-reducer";

function RecognitionComponent({twisterText, setDiffTwister, twisterLength, setInterimResult}: RecognitionComponentInterface) {
    const dispatch = useDispatch();
    const setPlayerScoreDispatch = useCallback((score: number) => dispatch(setPlayerScore(score)), [dispatch]);
    const setSelectedTwisterIndexDispatch = useCallback((index: number) => dispatch(setTwisterIndex(index)), [dispatch]);

    const twister: TwisterInterfaces = useSelector((state: ReturnType<typeof rootReducer>) => state.twister);

    const [startTimer, setStartTimer] = useState(0);
    const [matchedTime, setMatchedTime] = useState(Infinity);
    const [matchedPercent, setMatchedPercent] = useState(0);

    const onRecordingStartCallback = useCallback(() => resetTwisterResult(setStartTimer, setDiffTwister, setMatchedTime, setMatchedPercent), [setDiffTwister]);
    const onRecordingCompleteCallback = useCallback((transcript: string) => {
        onRecordingComplete(transcript, startTimer,
            twisterText,
            twister,
            setPlayerScoreDispatch,
            setDiffTwister,
            setMatchedTime,
            setMatchedPercent)
    }, [setDiffTwister, setPlayerScoreDispatch, startTimer, twister, twisterText]);

    const OnClickNextButton = (counter: number) => {
        onClickNextButton(twister.twisterIndex, setSelectedTwisterIndexDispatch, counter, twisterLength)
    };

    useEffect(()=>{
        resetTwisterResult(setStartTimer, setDiffTwister, setMatchedTime, setMatchedPercent);
    },[setDiffTwister, twisterText]);

    const {error, isRecording, startSpeechToText, stopSpeechToText} = TongueTwisterHookComponent(onRecordingStartCallback, onRecordingCompleteCallback, setInterimResult);

    return <>
        {
            matchedTime === Infinity ||
            <div className={"d-flex flex-column justify-content-center p-2"}>
                <div className={"d-flex justify-content-center"}>
                    {
                        matchedTime === Infinity || (!isRecording && Share.canShare() &&
                            <div className={"p-2 mb-2 border bg-white rounded-circle hw_64 d-flex justify-content-center align-items-center shadow"}>
                                <FontAwesomeIcon icon={"share-alt"}
                                                 className={"cursor-pointer"}
                                                 onClick={() => OnClickShareButton(twisterText, matchedTime, matchedPercent)}
                                                 size={"2x"}/>
                            </div>)
                    }
                </div>
                <div className={"text-center monaco-font"}>
                    <b className={"me-1"}>You able to match </b>
                    {matchedPercent}% <b>within </b>
                    {matchedTime} sec.
                </div>
                {
                    matchedPercent !== 100 &&
                    <div className={"text-danger text-center"}>
                        <p className={"text-secondary small m-0 monaco-font"}>
                            Record again to try your best
                        </p>
                        <p className={"small text-secondary m-0"}>
                            Do improve your time by better pronouncing with perfection
                        </p>
                    </div>
                }
            </div>
        }
        <div className={"p-2 d-flex flex-column justify-content-center hide-in-screenshot"}>
            {
                !isRecording && matchedTime === Infinity &&
                <div className={"pb-2 text-center"}>
                    <p className={"small text-secondary m-0"}>
                        Can you pronounce this in a single breathe?
                    </p>
                    <p className={"small text-secondary m-0"}>
                        click here to record now
                    </p>
                </div>
            }

            <div className={"shadow-none d-flex justify-content-center"}>
                {
                    !isRecording &&
                    <div className={"p-2 my-1 mx-2 bg-white rounded-circle hw_64 d-flex justify-content-center align-items-center shadow"}>
                        <FontAwesomeIcon icon={"arrow-alt-circle-left"}
                                         className={"cursor-pointer"}
                                         onClick={() => OnClickNextButton(-1)}
                                         size={"2x"}/>
                    </div>
                }

                {!error && <div className={"p-2 my-1 mx-2 bg-white rounded-circle hw_64 d-flex justify-content-center align-items-center shadow zoom-in-out-animation"}>
                    <FontAwesomeIcon icon={isRecording ? "microphone" : "microphone-slash"}
                                     className={"cursor-pointer"}
                                     onClick={isRecording ? stopSpeechToText : startSpeechToText}
                                     color={isRecording ? "green" : "black"} size={"2x"}/>
                </div>}

                {
                    !isRecording &&
                    <div className={"p-2 my-1 mx-2 bg-white rounded-circle hw_64 d-flex justify-content-center align-items-center shadow"}>
                        <FontAwesomeIcon icon={"arrow-alt-circle-right"}
                                         className={"cursor-pointer"}
                                         onClick={() => OnClickNextButton(1)}
                                         size={"2x"}/>
                    </div>
                }
            </div>

            <div className={`d-flex justify-content-center ${isRecording || "invisible"}`}>
                <p className={"small text-secondary m-0"}>
                    Recording is started, try reading tongue twister loudly
                </p>
            </div>
        </div>
        <div className={"p-2 display-6 text-primary fw-bold text-center show-in-screenshot"} >
            Hey can you beat my score
        </div>

        {
            error && <div
                className={"text-center p-2 pb-3 text-danger monaco-font"}>
                Speech recognition is not available
            </div>
        }
    </>
}

export default RecognitionComponent;
