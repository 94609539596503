import {CacheActionTypes} from "./url.types";

export const setURLCache = (key: string, response: string) => (
    {
        type: CacheActionTypes.SET_CACHE_RESPONSE,
        key: key,
        response: response
    }
)

export const resetCache = () => (
    {
        type: CacheActionTypes.RESET_CACHE
    }
)
