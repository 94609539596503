export function isNullOrUndefinedObject(object: any) {
    return object === null || object === undefined;
}

export function isEmptyString(text: string) {
    return isNullOrUndefinedObject(text) || text === '';
}

export function isEmptyObject(object: any) {
    return isNullOrUndefinedObject(object);
}

export function isEmptyArray(array: any[]) {
    return isNullOrUndefinedObject(array) || array.length <= 0;
}