import {BackendGETRequest} from "../../webrequests/BackendRequest";
import {VERSION_CHECK_ANDROID_API, VERSION_CHECK_IOS_API} from "../../constants/backend/APIConstant";
import {isPlatform} from "@ionic/react";
import {isEmptyString} from "../../utils/validators/Validator";
import {ShowUpdateModal} from "../modals/centermodal/CenterModalScreenManager";
import {VERSION_CODE} from "../../constants/Config";

export function VersionCheck(props: any) {
    let API = '';
    if (isPlatform("android")) {
        API = VERSION_CHECK_ANDROID_API;
    } else if (isPlatform("ios")) {
        API = VERSION_CHECK_IOS_API;
    }

    if (!isEmptyString(API)) {
        BackendGETRequest(API, (response: any) => {
            const versionCode = parseInt(response.body);
            if (versionCode > VERSION_CODE) {
                ShowUpdateModal(props, "New update is available", "Upgrade your app, upgrade your experience! Unlock the latest features, improvements, and a seamless interface. Stay ahead and enjoy the best – update to the newest version now! 🚀📱✨");
            }
        }, () => {
        });
    }
}
