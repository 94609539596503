import {TwisterActionTypes} from "./twister.types";
import {ActionInterface} from "../interfaces/ActionInterface";
import {TwisterInterfaces} from "../../main/pixiemart/interfaces/TwisterInterfaces";

const INITIAL_STATE_TWISTER: TwisterInterfaces = {
    selectedLang: '',
    selectedLevel: '0',
    playerScore: 0,
    twisterIndex: 0
}

const twisterReducer = (state = INITIAL_STATE_TWISTER, action: ActionInterface) => {
    switch (action.type) {
        case TwisterActionTypes.SET_TWISTER_LANG:
            return {
                ...state,
                selectedLang: action.payload
            }
        case TwisterActionTypes.SET_TWISTER_LEVEL:
            return {
                ...state,
                selectedLevel: action.payload
            }
        case TwisterActionTypes.SET_PLAYER_SCORE:
            return {
                ...state,
                playerScore: action.payload
            }
        case TwisterActionTypes.SET_TWISTER_INDEX:
            return {
                ...state,
                twisterIndex: action.payload
            }
        case TwisterActionTypes.CLEAR_TWISTER:
            return INITIAL_STATE_TWISTER;
        default:
            return state;
    }
}

export default twisterReducer;
