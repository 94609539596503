import {GetAppBackendUrl} from "../../utils/PixieMartUtil";

const TWISTER_BASE_API = GetAppBackendUrl() + "/api/pixie/twister";
export const TONGUE_TWISTER_API  = TWISTER_BASE_API + "/TWISTER_LEVEL/TWISTER_INDEX";
export const TWISTER_LANGUAGES_API = TWISTER_BASE_API + "/lang";

export const TWISTER_LANG_PARAM = "TWISTER_LANG";
export const TWISTER_LEVEL_PARAM = "TWISTER_LEVEL";
export const TWISTER_LANGUAGE_LEVELS_API = TWISTER_LANGUAGES_API + "/" + TWISTER_LANG_PARAM + "/level";
export const TONGUE_TWISTER_FOR_LEVEL_API = TWISTER_LANGUAGE_LEVELS_API + "/" + TWISTER_LEVEL_PARAM;
export const VERSION_CHECK_ANDROID_API = TWISTER_BASE_API +  "/version/platform/android";
export const VERSION_CHECK_IOS_API = TWISTER_BASE_API +  "/version/platform/ios";
