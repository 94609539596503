import {CacheUrlInterface} from "../interfaces/CacheUrlInterface";
import {CacheActionInterface} from "../interfaces/ActionInterface";
import {CacheActionTypes} from "./url.types";

const INITIAL_STATE_CACHE: CacheUrlInterface = {
    timestamp: new Date().valueOf(),
    cache: {}
}

const cacheReducer = (state = INITIAL_STATE_CACHE, action: CacheActionInterface) => {
    switch (action.type) {
        case CacheActionTypes.SET_CACHE_RESPONSE:
            return {
                ...state,
                cache: {...state.cache, [action.key]: action.response}
            }
        case CacheActionTypes.RESET_CACHE:
            return {
                ...state,
                cache: {}
            };
        default:
            return state;
    }
}

export default cacheReducer;
