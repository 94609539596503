import React, {useEffect, useState} from "react";
import {isEmptyArray} from "../../../utils/validators/Validator";
import {Form, Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface DropDownProps {
    items: DropDownItemInterface[],
    name?: string,
    placeholder?: string,
    required?: boolean,
    defaultValue?: string,
    onChangeCallback: (value: string) => void,
}

export interface DropDownItemInterface {
    name: string;
    value: string;
    image?: string;
}

export function DropDownBottomSelect(props: DropDownProps) {
    const [selectedDropDownItem, setSelectedDropDownItem] = useState<DropDownItemInterface>({name: "", value: ""});
    const [isShowPanel, setIsShowPanel] = useState(false);

    useEffect(() => {
        OnChangeDropDownItem(props.items, setSelectedDropDownItem, props.onChangeCallback, props.defaultValue);
    }, [props.defaultValue, props.items, props.onChangeCallback]);

    if (isEmptyArray(props.items)) {
        return <div className={"w-100 p-1 px-3 rounded border text-secondary"}>
            <Spinner size={"sm"}/> Loading {props.name}
        </div>;
    }

    return (
        <div className={"w-100"}>
            <div className={"text-dark position-relative"} onClick={() => {
                setIsShowPanel((isp) => !isp)
            }}>
                <div className={"border rounded position-relative overflow-hidden text-truncate"}
                     style={{padding: ".375rem 2.25rem .375rem .75rem"}}>
                    {selectedDropDownItem.name}
                    <div className={"position-absolute end-0 top-0 d-flex align-items-center h-100 px-2"}>
                        <FontAwesomeIcon icon={"chevron-down"} color={"grey"}/>
                    </div>
                </div>
                <Form.Control className={"visually-hidden"} name={props.name}
                              required={props.required} value={selectedDropDownItem.value}
                              onChange={() => {
                              }}
                              placeholder={props.placeholder}/>
            </div>

            {isShowPanel &&
                <div className={"w-100 h-100 position-absolute right-0 top-0 d-flex flex-column justify-content-end"}
                     style={{zIndex: 1, background: 'rgba(0,0,0,0.64)'}} onClick={() => {
                    setIsShowPanel(false);
                }}>
                    <div className={"bg-white rounded-5 rounded-bottom-0 overflow-y-auto"} style={{maxHeight: '80vh'}} >
                        {
                            props.items.map((item, index) => {
                                return <div key={props.name + "_drop_item_" + index}
                                            className={(props.items.length === index + 1) ? "" : "border-bottom"}
                                            onClick={() => {
                                                OnChangeDropDownItem(props.items, setSelectedDropDownItem, props.onChangeCallback, item.value);
                                            }}>
                                    <div className={"p-3 cursor-pointer overflow-hidden"}>
                                        {item.name}
                                        {
                                            item.image && <div className={"float-end overflow-hidden h-100 px-2"}>
                                                <img src={item.image} alt={"im_" + index}
                                                     className={"img-object-fit-cover"}/>
                                            </div>
                                        }
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>
            }
        </div>
    );
}

function OnChangeDropDownItem(items: DropDownItemInterface[],
                              setSelectedDropDownItem: React.Dispatch<React.SetStateAction<DropDownItemInterface>>,
                              onChangeCallback: (value: string) => void,
                              selectedValue?: string) {
    if (items.length === 0) return;
    let index = items.findIndex(item => item.value === selectedValue);
    if (index < 0) {
        index = 0;
    }

    setSelectedDropDownItem(items[index]);
    onChangeCallback(items[index].value);
}
