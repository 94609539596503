import React, {useCallback, useEffect, useState} from 'react';
import {BackendGETRequest} from "../../../webrequests/BackendRequest";
import {
    TONGUE_TWISTER_FOR_LEVEL_API, TWISTER_LANG_PARAM, TWISTER_LEVEL_PARAM
} from "../../../constants/backend/APIConstant";
import {isEmptyString, isNullOrUndefinedObject} from "../../../utils/validators/Validator";
import "./home.css";
import {DiffInterface, TwisterInterface} from "../TwisterInterfaces";
import RecognitionComponent from "../RecognitionComponent";
import {TwisterInterfaces} from "../../../interfaces/TwisterInterfaces";
import Footer from "../../footer/Footer";
import {useDispatch, useSelector} from "react-redux";
import {setTwisterIndex} from "../../../../../redux/twister/twister.action";
import rootReducer from "../../../../../redux/root-reducer";
import {CacheUrlInterface} from "../../../../../redux/interfaces/CacheUrlInterface";
import {setURLCache} from "../../../../../redux/urlcache/url.action";
import {
    CenterTwisterDisplay,
    TwisterScoreDisplay,
    TwisterSelectorDisplay,
    TwisterTitleDisplay
} from "./TwisterSections";

export function TwisterHome() {
    const dispatch = useDispatch();
    const setSelectedTwisterIndexDispatch = useCallback((index: number) => dispatch(setTwisterIndex(index)), [dispatch]);

    const twister: TwisterInterfaces = useSelector((state: ReturnType<typeof rootReducer>) => state.twister);

    const [diffTwister, setDiffTwister] = useState<DiffInterface[]>([]);
    const [twisters, setTwisters] = useState<TwisterInterface[]>([]);
    const [interimResult, setInterimResult] = useState('');

    // const setSelectedLangDispatch = useCallback((text: string) => dispatch(setSelectedLang(text)), [dispatch]);
    const setURLCacheDispatch = useCallback((key: string, response: string) => dispatch(setURLCache(key, response)), [dispatch]);
    const cache: CacheUrlInterface = useSelector((state: ReturnType<typeof rootReducer>) => state.cache);

    useEffect(() => {
        if (!isEmptyString(twister.selectedLevel)) {
            BackendGETRequest(TONGUE_TWISTER_FOR_LEVEL_API.replace(TWISTER_LANG_PARAM, twister.selectedLang).replace(TWISTER_LEVEL_PARAM, twister.selectedLevel), (response: any) => {
                const responseTwister = response.body.twisters as TwisterInterface[];
                if(isNullOrUndefinedObject(twister.twisterIndex) || twister.twisterIndex >= responseTwister.length) {
                    setSelectedTwisterIndexDispatch(0);
                }
                setTwisters(responseTwister);
            }, (err: any) => {
                console.error(err);
            }, true, cache, setURLCacheDispatch);
        }
    }, [cache, setSelectedTwisterIndexDispatch, setURLCacheDispatch, twister.selectedLang, twister.selectedLevel, twister.twisterIndex]);

    return <section className={"flex-fill d-flex flex-column"}>
        <div className={"text-center p-3"}>
            <TwisterTitleDisplay/>
            <TwisterScoreDisplay/>
            <TwisterSelectorDisplay/>
        </div>

        <CenterTwisterDisplay  diffTwister={diffTwister} interimResult={interimResult}
                               twisterLength={twisters.length} twisterText={twisters[twister.twisterIndex]?.twister} />

        <RecognitionComponent twisterText={twisters[twister.twisterIndex]?.twister}
                              setDiffTwister={setDiffTwister}
                              setInterimResult={setInterimResult}
                              twisterLength={twisters.length}/>

        <Footer/>
    </section>;

}

export default TwisterHome;
