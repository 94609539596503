// modal
import {ErrorModelScreenInterface} from "./interfaces/ErrorModelScreenInterface";
import {TwisterInterface} from "./interfaces/TwisterInterface";

export function ShowLoadingScreen(props: any, isShow: boolean) {
    props.showLoadingScreen(isShow);
}

export function SetErrorModalScreen(props: any, modelData: ErrorModelScreenInterface) {
    props.setErrorModalScreen(modelData);
}

// twister
export function SetTongueTwister(props: any, twister: TwisterInterface) {
    props.setTongueTwister(twister);
}

export function ClearTongueTwister(props: any) {
    props.clearTongueTwister();
}

export function SetTwisterLevel(props: any, level: string) {
    props.setTwisterLevel(level);
}

export function SetPlayerScore(props: any, score: number) {
    props.setPlayerScore(score);
}

export function SetTwisterIndex(props: any, index: number) {
    props.setTwisterIndex(index);
}
