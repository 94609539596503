import React, {Component} from 'react';
import {Button, Modal} from "react-bootstrap";
import {Connector} from "../../../../../redux/Connector";
import {HideUpdateModal} from "./CenterModalScreenManager";
import logo512 from "../../../statics/images/logo512.png";

interface Props {
    modal: any
}

class CenterScreenModal extends Component<Props> {
    constructor(props: any) {
        super(props);
        this.closeErrorModal = this.closeErrorModal.bind(this);
        HideUpdateModal(this.props);
    }

    closeErrorModal() {
        HideUpdateModal(this.props);
    }

    render() {
        return (
            <div>
                <Modal show={this.props.modal.error_modal_screen.show} onHide={this.closeErrorModal} className={"pm-modal"} centered>
                    <Modal.Body className={"text-center position-relative"}>
                        {
                            <div className={"d-flex justify-content-center"}>
                                <div className={"rounded overflow-hidden"}>
                                    <img src={logo512} alt={"logo"} className={"img-object-fit-contain"} style={{height: "8vh"}}/>
                                </div>
                            </div>
                        }
                        <div className={"fw-bold py-2"}>
                            <h4>
                                {this.props.modal.error_modal_screen.label}
                            </h4>
                        </div>
                        <div className={"py-2"} style={{whiteSpace: "pre-wrap"}}>
                            <p>
                                {this.props.modal.error_modal_screen.content}
                            </p>
                        </div>
                        <div className={"w-100"}>
                            <Button variant={"secondary"} className={"mx-1"} onClick={this.closeErrorModal} style={{minWidth: 120}}>
                                Update Later
                            </Button>
                            <Button onClick={()=> {window.open('https://play.google.com/store/apps/details?id=com.mart.pixie.twister.tongue', '_blank', 'noopener,noreferrer');}} variant={"success"} className={"mx-1"} style={{minWidth: 120}}>
                                Update Now
                            </Button>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default Connector(CenterScreenModal);
