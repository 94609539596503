import {connect} from "react-redux";
import {
    setErrorModalScreen,
    showLoadingScreen
} from "./modal/modal.actions";
import {
    setPlayerScore, setSelectedLang,
    setTwisterIndex,
    setTwisterLevel
} from "./twister/twister.action";
import {ErrorModelScreenInterface} from "./interfaces/ErrorModelScreenInterface";
import {Dispatch, JSXElementConstructor} from "react";
import {Action} from "redux";
import rootReducer from "./root-reducer";
import {resetCache, setURLCache} from "./urlcache/url.action";

const mapStateToProps = (state: ReturnType<typeof rootReducer>) => (
    {
        modal: state.modal,
        twister: state.twister
    }
)

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    // modal
    showLoadingScreen: (isShow: boolean) => dispatch(showLoadingScreen(isShow)),
    setErrorModalScreen: (modelData: ErrorModelScreenInterface) => dispatch(setErrorModalScreen(modelData)),

    // twister
    setSelectedLang: (lang: string) => dispatch(setSelectedLang(lang)),
    setTwisterLevel: (level: string) => dispatch(setTwisterLevel(level)),
    setPlayerScore: (score: number) => dispatch(setPlayerScore(score)),
    setTwisterIndex: (index: number) => dispatch(setTwisterIndex(index)),

    // url cache
    setURLCache: (key: string, response: string) => dispatch(setURLCache(key, response)),
    resetCache: () => dispatch(resetCache())
})

export const Connector = (component: JSXElementConstructor<any>) => {
    return connect(mapStateToProps, mapDispatchToProps)(component);
}
