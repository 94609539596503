import useSpeechToText, {ResultType} from "react-hook-speech-to-text";
import {GOOGLE_SPEECH_TO_TEXT_API} from "../../constants/Config";
import {Dispatch, SetStateAction, useEffect} from "react";
import {TwisterInterfaces} from "../../interfaces/TwisterInterfaces";
import {useSelector} from "react-redux";
import rootReducer from "../../../../redux/root-reducer";



export const TongueTwisterHookComponent = (
    onRecordingStart: () => void,
    onRecordingComplete: (data: string) => void,
    setInterimResult: Dispatch<SetStateAction<string>>,
) => {

    const twister: TwisterInterfaces = useSelector((state: ReturnType<typeof rootReducer>) => state.twister);
    const {
        error,
        interimResult,
        isRecording,
        results,
        setResults,
        startSpeechToText,
        stopSpeechToText,
    } = useSpeechToText({
        continuous: false,
        useLegacyResults: false,
        timeout: 10000,  // ms
        useOnlyGoogleCloud: false,
        crossBrowser: true,
        googleApiKey: GOOGLE_SPEECH_TO_TEXT_API,
        googleCloudRecognitionConfig: {
            languageCode: getLanguageCode(twister.selectedLang)
        },
        speechRecognitionProperties: {
            lang: getLanguageCode(twister.selectedLang),
            interimResults: true // Allows for displaying real-time speech results
        }
    });

    // Reset the results if recording is stopped
    useEffect(() => {
        if(!isRecording) {
            setResults([])
        } else {
            onRecordingStart();
            console.log("Recording started");
        }
    }, [isRecording, setResults, onRecordingStart]);

    // On complete the recording
    useEffect(() => {
        if(results.length > 0) {
            const result = results[results.length - 1] as ResultType;
            onRecordingComplete(result.transcript);
        }
    }, [results, onRecordingComplete]);

    useEffect(() => {
        setInterimResult(interimResult || '');
    }, [interimResult, setInterimResult]);


    return {error, isRecording, startSpeechToText, stopSpeechToText};
}

function getLanguageCode(lang: string) {
    if (lang === 'HIN' || lang === 'SANSKRIT') {
        return 'hi-IN';
    } else if (lang === 'KANNADA') {
        return 'kn-IN';
    } else if (lang === 'TAMIL') {
        return 'ta-IN';
    } else if (lang === 'EN') {
        return 'en-IN';
    }

    return 'en-IN';
}
// DUMMPY
