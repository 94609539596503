import {TwisterActionTypes} from "./twister.types";

export const setSelectedLang = (lang: string) => (
    {
        type: TwisterActionTypes.SET_TWISTER_LANG,
        payload: lang
    }
)

export const setTwisterLevel = (level: string) => (
    {
        type: TwisterActionTypes.SET_TWISTER_LEVEL,
        payload: level
    }
)

export const setPlayerScore = (score: number) => (
    {
        type: TwisterActionTypes.SET_PLAYER_SCORE,
        payload: score
    }
)

export const setTwisterIndex = (index: number) => (
    {
        type: TwisterActionTypes.SET_TWISTER_INDEX,
        payload: index
    }
)
