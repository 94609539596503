import React, {useCallback, useEffect, useState} from "react";
import {TwisterInterfaces} from "../../../interfaces/TwisterInterfaces";
import {useDispatch, useSelector} from "react-redux";
import rootReducer from "../../../../../redux/root-reducer";
import DropDownSelect, {DropDownItemInterface} from "../../general/dropdownselect/DropDownSelect";
import {setSelectedLang, setTwisterLevel} from "../../../../../redux/twister/twister.action";
import {BackendGETRequest} from "../../../webrequests/BackendRequest";
import {
    TWISTER_LANG_PARAM,
    TWISTER_LANGUAGE_LEVELS_API,
    TWISTER_LANGUAGES_API
} from "../../../constants/backend/APIConstant";
import {isEmptyString} from "../../../utils/validators/Validator";
import {setURLCache} from "../../../../../redux/urlcache/url.action";
import {CacheUrlInterface} from "../../../../../redux/interfaces/CacheUrlInterface";
import {DiffInterface} from "../TwisterInterfaces";
import {DropDownBottomSelect} from "../../general/dropbottom/DropDownBottomSelect";
import {isBrowser} from "../../../utils/PlatformUtils";

export function TwisterTitleDisplay() {
    return <div>
        <h1 className={"fw-bold"}>
            Tongue Twister
        </h1>
        <p className={"small text-secondary"}>
            Twist and turn your way to speech perfection and fluency -
            master the challenge of the tongue
            twister!
        </p>
    </div>
}

export function TwisterScoreDisplay() {
    const twister: TwisterInterfaces = useSelector((state: ReturnType<typeof rootReducer>) => state.twister);

    return <div className={"d-flex justify-content-center"}>
        <div className={"p-1 border bg-white"}>
            <b>Your Score: </b> {twister.playerScore || '0'}
        </div>
        <div className={"p-1 border bg-white"}>
            <b>World Record: </b> 934783
        </div>
    </div>
}

export function TwisterSelectorDisplay() {
    const dispatch = useDispatch();
    const twister: TwisterInterfaces = useSelector((state: ReturnType<typeof rootReducer>) => state.twister);
    const cache: CacheUrlInterface = useSelector((state: ReturnType<typeof rootReducer>) => state.cache);

    const setSelectedLangDispatch = useCallback((text: string) => dispatch(setSelectedLang(text)), [dispatch]);
    const setTwisterLevelDispatch = useCallback((level: string) => dispatch(setTwisterLevel(level)), [dispatch]);
    const setURLCacheDispatch = useCallback((key: string, response: string) => dispatch(setURLCache(key, response)), [dispatch]);

    const [languages, setLanguages] = useState<DropDownItemInterface[]>([]);
    const [langLevels, setLangLevels] = useState<DropDownItemInterface[]>([]);


    useEffect(() => {
        BackendGETRequest(TWISTER_LANGUAGES_API, (response: any) => {
            setLanguages(response.body.languages as DropDownItemInterface[]);
        }, (err: any) => {
            console.error(err);
        }, true, cache, setURLCacheDispatch);
    }, [cache, setURLCacheDispatch]);

    useEffect(() => {
        if (!isEmptyString(twister.selectedLang)) {
            BackendGETRequest(TWISTER_LANGUAGE_LEVELS_API.replace(TWISTER_LANG_PARAM, twister.selectedLang), (response: any) => {
                setLangLevels(response.body.levels as DropDownItemInterface[]);
            }, (err: any) => {
                console.error(err);
            }, true, cache, setURLCacheDispatch);
        }
    }, [cache, setURLCacheDispatch, twister.selectedLang]);

    return <div className={"d-flex justify-content-center py-1"}>
        <div className={"m-1 bg-white rounded"}>
            {
                isBrowser() ? <DropDownSelect items={languages}
                                              name={"language"}
                                              defaultValue={twister.selectedLang}
                                              onChangeCallback={setSelectedLangDispatch}/> :
                    <DropDownBottomSelect items={languages}
                                          name={"language"}
                                          defaultValue={twister.selectedLang}
                                          onChangeCallback={setSelectedLangDispatch}/>
            }
        </div>
        {
            languages.length > 0 &&
            <div className={"m-1 bg-white rounded"}>
                {
                    isBrowser() ? <DropDownSelect items={langLevels}
                                                  name={"level"}
                                                  defaultValue={twister.selectedLevel}
                                                  onChangeCallback={setTwisterLevelDispatch}/> :
                        <DropDownBottomSelect items={langLevels}
                                              name={"level"}
                                              defaultValue={twister.selectedLevel}
                                              onChangeCallback={setTwisterLevelDispatch}/>
                }
            </div>
        }
    </div>
}

export function CenterTwisterDisplay({diffTwister, interimResult, twisterLength, twisterText}:
                                         {diffTwister: DiffInterface[], interimResult: string, twisterLength: number, twisterText?: string}) {
    const twister: TwisterInterfaces = useSelector((state: ReturnType<typeof rootReducer>) => state.twister);

    return <div
        className={"overflow-y-auto d-flex flex-column justify-content-center align-items-center flex-fill pb-3 text-center"}>
        {
            twisterText &&
            <div className={"py-2"}>
                <div
                    className={"rounded-circle border fw-bold text-secondary d-flex align-items-center justify-content-center bg-whit hw_64"}>
                    {twister.twisterIndex + 1} / {twisterLength}
                </div>
            </div>
        }
        <div
            className={"fw-bold text-capitalize fs_heading text-primary hide-in-screenshot slide-in-animation px-2"}>
            {
                twisterText || "Loading..."
            }
        </div>
        <div style={{minHeight: 40}} className={"p-2"}>
            {
                <div
                    className={`p-2 bg-white text-capitalize rounded fw-bold d-flex align-items-center ${interimResult || "invisible"}`}>
                    {interimResult}
                </div>
            }
            {
                <div
                    className={`p-2 bg-white text-capitalize rounded fw-bold ${diffTwister.length > 0 || "invisible"}`}>
                    {
                        diffTwister.map((twister_part, index) =>
                            <span className={`${twister_part.color}`}
                                  key={"twist_index" + index}>
                                                                                   {twister_part.value}{' '}
                                                                               </span>)
                    }
                </div>
            }
        </div>
    </div>
}
