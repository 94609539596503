import React, {useEffect, useState} from 'react';
import {Dropdown, Form, Spinner} from "react-bootstrap";
import "./dropdownselect.css";
import {isEmptyArray} from "../../../utils/validators/Validator";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface DropDownProps {
    items: DropDownItemInterface[],
    name?: string,
    placeholder?: string,
    required?: boolean,
    defaultValue?: string,
    onChangeCallback: (value: string) => void,
}

export interface DropDownItemInterface {
    name: string;
    value: string;
    image?: string;
}

function OnChangeDropDownItem(items: DropDownItemInterface[],
                              setSelectedDropDownItem: React.Dispatch<React.SetStateAction<DropDownItemInterface>>,
                              onChangeCallback: (value: string) => void,
                              selectedValue?: string | null) {
    if(items.length === 0) return;
    let index = items.findIndex(item => item.value === selectedValue);
    if(index < 0) {index = 0;}

    setSelectedDropDownItem(items[index]);
    onChangeCallback(items[index].value);
}

function DropDownSelect(props: DropDownProps) {
    const [selectedDropDownItem, setSelectedDropDownItem] = useState<DropDownItemInterface>({name: "", value: ""});

    useEffect(() => {
        OnChangeDropDownItem(props.items, setSelectedDropDownItem, props.onChangeCallback, props.defaultValue);
    }, [props.defaultValue, props.items, props.onChangeCallback]);

    if (isEmptyArray(props.items)) {
        return <div className={"w-100 p-1 px-3 rounded border text-secondary"}>
            <Spinner size={"sm"}/> Loading {props.name}
        </div>;
    }

    return (
        <div className={"w-100"}>
            <Dropdown onSelect={(element) => OnChangeDropDownItem(props.items, setSelectedDropDownItem, props.onChangeCallback, element)}>
                <Dropdown.Toggle className={"bg-transparent shadow-none w-100"} bsPrefix={"g-0"}>
                    <div className={"text-dark position-relative"}>
                        <div className={"border rounded position-relative overflow-hidden text-truncate"}
                             style={{padding: ".375rem 2.25rem .375rem .75rem"}}>
                            {selectedDropDownItem.name}
                            <div className={"position-absolute end-0 top-0 d-flex align-items-center h-100 px-2"}>
                                <FontAwesomeIcon icon={"chevron-down"} color={"grey"}/>
                            </div>
                        </div>
                        <Form.Control className={"visually-hidden"} name={props.name}
                                      required={props.required} value={selectedDropDownItem.value}
                                      onChange={() => {
                                      }}
                                      placeholder={props.placeholder}/>
                    </div>
                </Dropdown.Toggle>

                <Dropdown.Menu className={"w-100 overflow-auto shadow border"} style={{maxHeight: "50vh"}}>
                    {
                        props.items.map((item, index) => {
                            return <Dropdown.Item key={props.name + "_drop_item_" + index} eventKey={item.value}
                                                  className={(props.items.length === index + 1) ? "" : "border-bottom"}>
                                <div className={"p-1 cursor-pointer overflow-hidden"} style={{height: 32}}>
                                    {item.name}
                                    {
                                        item.image && <div className={"float-end overflow-hidden h-100 px-2"}>
                                            <img src={item.image} alt={"im_" + index} className={"img-object-fit-cover"}/>
                                        </div>
                                    }
                                </div>
                            </Dropdown.Item>
                        })
                    }
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );

}

export default DropDownSelect;
