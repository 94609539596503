import { combineReducers } from "redux";
import modalReducer from "./modal/modalReducer";
import twisterReducer from "./twister/twister.reducer";
import cacheReducer from "./urlcache/urls.reducer";

export default combineReducers(
    {
        modal: modalReducer,
        twister: twisterReducer,
        cache: cacheReducer
    }
)
