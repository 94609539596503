import axios from "axios";
import {optionProvider} from "./RequestConfig";
import {FAILURE_STATUS, SUCCESS_STATUS} from "../constants/Constant";
import {GaEventTracker} from "../google/analytics/GoogleAnalyticsEventTracker";
import base64 from 'react-native-base64';
import {CacheUrlInterface} from "../../../redux/interfaces/CacheUrlInterface";

export function BackendGETRequest(URL: string, successCallback: any, failureCallback: any, isCache?: boolean, cache?: CacheUrlInterface, setURLCacheDispatch?: (key: string, response: string) => void) {
    console.debug('GET request', URL);
    if (isCache) {
        let response = cache?.cache[base64.encode(URL)];

        if (response) {
            let jsonResponse = JSON.parse(response);
            let timeDiff = Date.now().valueOf() - jsonResponse.time;
            // 10Hr = 36000000 ms
            if (timeDiff < 36000000) {
                console.log('LFC', timeDiff);
                successCallback(jsonResponse.data);
                return;
            }
        }
    }

    axios.get(URL, optionProvider())
        .then(response => {
            if(response.data.status === SUCCESS_STATUS) {
                successCallback(response.data);
                if (isCache && setURLCacheDispatch) {
                    setURLCacheDispatch(base64.encode(URL), JSON.stringify({time: Date.now().valueOf(), data: response.data}));
                }
                return;
            }
            if(failureCallback !== undefined && response.data.status === FAILURE_STATUS) {
                failureCallback(response);
                return;
            }
            console.warn("[GET] Something unexpected response: ", response);
        })
        .catch(error => {
            error.url = URL;
            console.error(error);
            GaEventTracker("[GET] Request failed: ", error);
            if(error.response !== undefined && error.response.status === 401 &&
                error.response.data !== undefined && error.response.data.status === FAILURE_STATUS &&
                error.response.data.message !== undefined && error.response.data.message.includes("authentication is required")) {
            }

            if(failureCallback !== undefined) {
                failureCallback(error);
            }
        });
}

export function BackendPOSTRequest(URL: string, data: any, successCallback: any, failureCallback: any) {
    axios.post(URL, data, optionProvider())
        .then(response => {
            if(response.data.status === SUCCESS_STATUS) {
                successCallback(response.data);
                return;
            }
            if(failureCallback !== undefined && response.data.status === FAILURE_STATUS) {
                failureCallback(response);
                return;
            }
            console.warn("[POST] Something unexpected response: ", response);
        })
        .catch(error => {
            error.url = URL;
            console.error(error);
            GaEventTracker("[POST] Request failed: ", error);
            if(failureCallback !== undefined) {
                failureCallback(error);
            }
        });
}
